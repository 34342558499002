@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --foreground-rgb: 25, 29, 58;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;

  /* COLORS */
  /* In order to Tailwind to be able to handle colors opacity,
     colors must be given in the format RedValue, GreenValue, BlueValue */
  /* --- Brand --- */
  --brand-primary: 0, 0, 251;
  --brand-secondary: 252, 66, 66;
  /* --- Grayscale --- */
  --gray-dark: 90, 103, 115;
  --gray-white: 248, 248, 252;
  --gray: 156, 169, 180;
  --gray-semi: 201, 212, 222;
  --gray-light: 245, 245, 250;
  /* --- Black & white --- */
  --black: 25, 29, 58;
  --white: 255, 255, 255;
  /* --- System --- */
  --system-success: 0, 247, 191;
  --system-error: 252, 66, 66;
  --system-warning: 255, 187, 0;
  --system-info: 0, 236, 255;
  /* --- UI --- */
  --ui-primary: 0, 0, 251;
  --ui-secondary: 252, 66, 66;
  --ui-neutral: 95, 108, 120;
}

@font-face {
  font-family: 'PolySans';
  src:
    url('/fonts/PolySans-Slim.woff2') format('woff2'),
    url('/fonts/PolySans-Slim.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'PolySans';
  src:
    url('/fonts/PolySans-NeutralWide.woff2') format('woff2'),
    url('/fonts/PolySans-NeutralWide.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'PolySans';
  src:
    url('/fonts/PolySans-Median.woff2') format('woff2'),
    url('/fonts/PolySans-Median.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@layer base {
  body {
    @apply typeface-body1-standard bg-white-white text-black-black;
  }
}

@layer components {
  input[type='checkbox']:checked + label {
    @apply bg-white-white text-black-black;
  }
  input[type='checkbox']:checked + label svg {
    @apply block;
  }
}

@layer utilities {
  .PreventFromScroll {
    @apply overflow-hidden relative;
  }

  .PreventFromScroll.OverlayBlur::after {
    @apply z-[500];
  }

  .OverlayBlur {
    @apply relative;

    &::after {
      content: '';
      @apply absolute top-none left-none w-full h-full z-20 duration-500 transition-all backdrop-blur-md;
    }
  }
}

[tabindex]:focus {
  outline: none;
  -webkit-tap-highlight-color: transparent;
}
